<template>

  <v-sheet
    v-if="loaders.loading"
    :color="`grey darken-2 `"
    class="pa-3"
  >
    <v-skeleton-loader
      class="mx-auto"
      type="card"
    ></v-skeleton-loader>
  </v-sheet>

  <v-card v-else
    elevation="0"
    :loading="loaders.loading"
    class="mx-auto "
    style=""
    max-width="1200"
  >

    <v-img v-if="contest.post"
           :src="contest.post.postimages[0].uploaddir+'700_'+contest.post.postimages[0].postimage"
           height="200" class=""
    >
      <template v-slot:placeholder>
        <v-row
          class="fill-height ma-0 "
          align="center"
          justify="center"
        >
          <v-progress-circular
            indeterminate
            color="grey lighten-5"
          ></v-progress-circular>
        </v-row>
      </template>
    </v-img>

    <v-toolbar
      color="cyan"
      flat
    >
      <!--          <v-app-bar-nav-icon></v-app-bar-nav-icon>-->

      <v-toolbar-title>{{ contest.cmstext.title }}</v-toolbar-title>

      <v-spacer></v-spacer>

      <v-btn
        v-if="!participant.id && contest.id"
        color="primary"
        :loading="loaders.enroll"
        @click="dialog.newenroll = true"
      >
        Je participe
      </v-btn>
      <v-btn v-else
             color="primary"
             :to="`/contest/participant/${participant.id}`"
      >
        Continuer les modifications
      </v-btn>
      <!--      <v-btn v-else
                   color="primary"
                   @click="replyPost"
            >
              Ajouter un episode
            </v-btn> -->

    </v-toolbar>
    <v-tabs
      v-model="tab"
      color="primary"
      grow
    >
      <v-tab key="cb">
        Detail sur le concours
      </v-tab>
      <v-tab key="mc">
        Participant ({{ contest.nb_participant }})
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="tab">
      <v-tab-item
        key="d"
        class="pa-0 pb-16"
      >
        <v-card class="pb-12" elevation="0">

          <v-card-text v-html="contest.cmstext.content"></v-card-text>

          <v-card-title>Envoyer mon travail</v-card-title>
          <v-card-text
            v-if="participant.id && contest.id"
            color="blue"
            type="warning"
          >
            <v-btn :to="`/contest/participant/${participant.id}`" color="primary">Continuer les
              modifications
            </v-btn>
          </v-card-text>
          <v-alert
            v-else-if="!user_id"
            color="blue"
            type="warning"
          >
            Vous devez etre
            <v-btn text @click="dialog.authentication = true">connecté</v-btn>
            pour renseigner les informations dans cette section.
            <v-btn color="primary" @click="dialog.authentication = true">Se connecter</v-btn>
          </v-alert>
          <v-card-text
            v-else
            color="blue"
            type="warning"
          >

            <v-btn v-if="contest.id" color="primary" @click="dialog.newenroll = true">Je participe
            </v-btn>
          </v-card-text>

          <v-card-title> Commentaire et Questions ({{ post.nbcomment }})
          </v-card-title>
          <v-card-text class="pa-0">
            <comment-component v-if="contest.id"
                               :limit="10"
                               :user="user"
                               :post_id="parseInt(contest.reference)"
                               :nbcomment="post.nbcomment"
                               @initlogin="()=>$emit('initlogin')"
            ></comment-component>
          </v-card-text>

        </v-card>
      </v-tab-item>
      <v-tab-item
        key="p"
        class="pa-0 pb-16"
      >
        <contest-participant-component
          :idcontest="contest.id"
          :user="user"
          :user_id="user_id"
        ></contest-participant-component>

      </v-tab-item>
    </v-tabs-items>


    <template slot="progress">
      <v-progress-linear
        color="deep-purple"
        height="10"
        indeterminate
      ></v-progress-linear>
    </template>

    <v-dialog
      v-model="dialog.authentication"
      max-width="600"
      fullscreen
    >
      <login-component
        :newaccount="false"
        @logged="logged"
        @closedialog="()=> {dialog.authentication = false}"
      ></login-component>
    </v-dialog>

    <v-dialog
      v-model="dialog.newenroll"
      max-width="500"
    >
      <v-card>
        <v-card-text class="pt-6">
          <p>Renseignez le titre de votre travail pour enregistrer votre participation au
            concours.</p>
          <v-text-field
            v-model="participant.title"
            label="Renseignez le titre ..."
          ></v-text-field>
          <v-btn
            color="primary"
            block
            :loading="loaders.enroll"
            @click="enroll"
          >
            Enregistrer ma participation
          </v-btn>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-bottom-sheet class="pb-6 overflow-auto"
                    v-model="loaders.replypost"
    >
      <post-form-component
        v-model="loaders.replypost"
        :postedit="{}"
        :postcategories="postcategories"
        :mainpost="post"
        @posted="posted"

        @close="cancelpost"
      >
      </post-form-component>
    </v-bottom-sheet>
  </v-card>
</template>

<script>
import {
  mdiFacebook,
  mdiChevronLeft,
  mdiClose,
} from '@mdi/js'
import {useRouter} from '@/utils'
import {ref} from '@vue/composition-api'
import {Drequest} from '@/plugins/Drequest'
import LoginComponent from '@/components/LoginComponent'
import CommentComponent from '@/components/CommentComponent'
import PostReplyFormComponent from '@/components/PostReplyFormComponent.vue'
import PostFormComponent from '@/components/PostFormComponent.vue'
import ChapterFormComponent from "@/components/ChapterFormComponent.vue";
import ProfileItem from "@/components/ProfileItem.vue";
import ContestParticipantItem from "@/components/ContestParticipantItem.vue";
import ContestParticipantComponent from "@/components/contest/ContestParticipantComponent.vue";

export default {
  name: 'Contest',
  components: {
    ContestParticipantComponent,
    ContestParticipantItem,
    ProfileItem,
    ChapterFormComponent,
    PostFormComponent,
    PostReplyFormComponent,
    CommentComponent,
    LoginComponent
  },
  setup() {

    const user_id = ref(localStorage.getItem('user_id'))
    const {router} = useRouter()
    const route = router.currentRoute
    const {id} = route.params
    const user = Drequest.getUser()
    const loaders = ref({
      loading: true,
      enroll: false,
      update: false,
      replypost: false,
      cardload: true,
    })
    const dialog = ref({
      authentication: false,
      submitted: false,
      newenroll: false,
    })
    const post = ref({})
    const contest = ref({cmstext: {}})
    const participant = ref({})
    const postcategories = ref([])
    const participation = ref({})
    const cover = ref(null)
    const panel = ref(0)
    const e1 = ref(1)

    var url = 'contest.init?user_id=' + user_id.value + '&id=' + id
    if (!user_id.value) {
      url = 'contest.init?id=' + id
    }
    Drequest.api(url)
      .get(response => {
        console.log(response)
        loaders.value.loading = false
        contest.value = response.contest
        post.value = contest.value.post
        participant.value = response.participation ?? {}
      })

    Drequest.api('lazyloading.tree-item?dfilters=on')
      .param({
        next: 1,
        per_page: 10,
        "status:eq": 1,
        'tree.name:eq': 'post_category',
        'dsort': 'slug asc',
      })
      .get(response => {
        console.log(response)
        if (response.listEntity.length) {
          postcategories.value = response.listEntity
        }
      })
    const enroll = () => {
      if (!user_id.value) {
        alert('Vous devez etre connecte pour participer')
        dialog.value.authentication = true
        return 0
      }

      if (!participant.value.title) {
        alert('Vous devez renseigner le titre')
        return 0
      }

      if (!confirm('Vous confirmez votre inscription au councours?')) {
        return 0
      }

      loaders.value.enroll = true
      Drequest.api('participant.create')
        .data({
          participant: {
            'user.id': user_id.value,
            'contest.id': id,
            'title': participant.value.title,
          }
        })
        .raw((response) => {
          if (response.success) {
            loaders.value.enroll = false
            alert('Votre inscription au concours est confirmée. n\'oubliez pas de publier regulierement l\'avancement de votre travail.')
            window.location.href = `/contest/participant/${response.participant.id}`
          }
          console.log(response)
        })
    }

    const logged = response => {
      console.log(response)
      window.location.reload()
    }

    const posted = post => {
      console.log(post)
      loaders.value.replypost = false
      //children.value.unshift(post)
      alert('Post publie')
    }
    const replyPost = () => {
      console.log(contest.value.post)
      post.value = contest.value.post
      loaders.value.replypost = true
    }
    const cancelpost = () => {
      loaders.value.replypost = false
    }
    const tab = ref('d')
    return {
      enroll,
      logged,
      posted,
      replyPost,
      cancelpost,

      postcategories,
      user_id,
      user,
      tab,
      post,
      contest,
      participation,
      participant,
      cover,
      loaders,
      dialog,
      panel,
      e1,
      icons: {
        mdiClose,
        mdiChevronLeft,
      }
    }
  }
}
</script>

<style scoped>

</style>
